<template>
    <div class="profile">
        <section class="first-section">
            <div class="container">
                <div class="row">
                    <div class="col-xl-8 offset-xl-2">
                        <b-tabs
                            class="nav-underlined"
                            content-class="mt-5"
                            fill
                        >
                            <b-tab
                                :title="$t('Profil adatok')"
                                :active="$route.params.tab === 'profile'"
                            >
                                <Personal />
                            </b-tab>
                            <b-tab
                                :title="$t('Megrendelések')"
                                :active="$route.params.tab === 'orders'"
                            >
                                <Orders />
                            </b-tab>
                            <!--<b-tab
                                :title="$t('Hírlevél')"
                                :active="$route.params.tab === 'newsletter'"
                            >
                                <Newsletter />
                            </b-tab>-->
                        </b-tabs>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import Personal from "../components/profile/Personal";
import Orders from "../components/profile/Orders";
//import Newsletter from "../components/profile/Newsletter";

export default {
    name: "Profile",
    components: { Personal, Orders }
};
</script>

<style scoped></style>
