<template>
    <div>
        <div class="row border-top border-bottom py-4 d-none d-md-flex">
            <div class="col-md-3">
                {{ $t("Rendelés száma") }}
            </div>
            <div class="col-md-3 text-center">
                {{ $t("Dátum") }}
            </div>
            <div class="col-md-2 text-center">
                {{ $t("Összeg") }}
            </div>
            <div class="col-md-4 text-end">
                {{ $t("Megrendelés állapota") }}
            </div>
        </div>

        <div
            class="row py-3"
            v-for="(item, index) in orders"
            v-bind:key="'order-item-' + index"
        >
            <div class="col-md-3">
                <strong>#{{ item.id }}</strong>
            </div>
            <div class="col-md-3 text-md-center">
                {{ item.created_at }}
            </div>
            <div class="col-md-2 text-md-center">{{ item.order_total }} Ft</div>
            <div class="col-md-4 text-md-end">
                {{ item.status_name }}
            </div>
        </div>
        <div class="py-4 border-top text-end">
            <div>
                {{ $t("Megrendelések száma") }}:
                <span>{{ orders.length }}</span>
            </div>
            <div>
                {{ $t("Megrendelések összege") }}:
                <span>{{ sumTotalOrder }} Ft</span>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "Orders",
    data() {
        return {
            orders: []
        };
    },
    computed: {
        ...mapGetters(["currentUser", "isAuthenticated"]),
        url: function() {
            return `${process.env.VUE_APP_API_URL}`;
        },
        sumTotalOrder: function() {
            let sum = 0;

            for (const it in this.orders) {
                sum += this.orders[it].order_total;
            }

            return sum;
        }
    },
    beforeMount() {
        this.getOrders();
    },
    methods: {
        getOrders() {
            const self = this;
            self.$http.get(`${self.url}/my-orders`).then(response => {
                self.orders = response.data.data;
            });
        }
    }
};
</script>

<style scoped></style>
