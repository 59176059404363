<template>
    <div v-if="model && model.billing">
        <form v-on:submit.prevent="updatePersonal">
            <div class="d-flex mb-4">
                <div class="form-check">
                    <input
                        class="form-check-input"
                        type="radio"
                        value="0"
                        id="usertype-1"
                        name="usertype"
                        v-model="model.billing.is_company"
                    />
                    <label class="form-check-label" for="usertype-1">
                        {{ $t("Magánszemély") }}
                    </label>
                </div>
                <div class="form-check mx-3">
                    <input
                        class="form-check-input"
                        type="radio"
                        value="1"
                        id="usertype-2"
                        name="usertype"
                        v-model="model.billing.is_company"
                    />
                    <label class="form-check-label" for="usertype-2">
                        {{ $t("Cég") }}
                    </label>
                </div>
            </div>

            <div class="row">
                <div class="col-xl-6">
                    <div class="form-floating mb-4" id="firstname">
                        <input
                            type="text"
                            name="lastname"
                            class="form-control mb-4"
                            :placeholder="$t('Vezetéknév')"
                            v-model="model.billing.lastname"
                        />
                        <label for="firstname">{{ $t("Vezetéknév") }}</label>
                    </div>
                </div>
                <div class="col-xl-6">
                    <div class="form-floating mb-4" id="lastname">
                        <input
                            type="text"
                            name="firstname"
                            class="form-control mb-4"
                            :placeholder="$t('Keresztnév')"
                            v-model="model.billing.firstname"
                        />
                        <label for="lastname">{{ $t("Keresztnév") }}</label>
                    </div>
                </div>
            </div>

            <div class="form-floating mb-4" id="phone">
                <input
                    type="tel"
                    name="phone"
                    class="form-control mb-4"
                    :placeholder="$t('Telefonszám')"
                    v-model="model.phone"
                />
                <label for="email">{{ $t("Telefonszám") }}</label>
            </div>

            <div class="form-floating mb-4" id="email">
                <input
                    type="email"
                    name="email"
                    class="form-control mb-4"
                    :placeholder="$t('E-mail')"
                    v-model="model.email"
                />
                <label for="email">{{ $t("E-mail") }}</label>
            </div>

            <div class="form-floating mb-4" id="password">
                <input
                    type="password"
                    name="password"
                    class="form-control mb-4"
                    :placeholder="$t('Jelszó')"
                    v-model="model.password"
                />
                <label for="password">{{ $t("Jelszó") }}</label>
            </div>

            <div class="form-floating mb-4" id="password-repeat">
                <input
                    type="password"
                    name="password_confirmation"
                    class="form-control mb-4"
                    :placeholder="$t('Jelszó újra')"
                    v-model="model.password_confirmation"
                />
                <label for="password-repeat">{{ $t("Jelszó újra") }}</label>
            </div>

            <div class="h3 text-center mt-100 mb-5">
                {{ $t("Számlázási adatok") }}
            </div>

            <div class="form-floating mb-4" id="country">
                <input
                    type="text"
                    name="country"
                    class="form-control mb-4"
                    :placeholder="$t('Ország')"
                    v-model="model.billing.country"
                />
                <label for="country">{{ $t("Ország") }}</label>
            </div>

            <div class="form-floating mb-4" id="city">
                <input
                    type="text"
                    name="city"
                    class="form-control mb-4"
                    :placeholder="$t('Város')"
                    v-model="model.billing.city"
                />
                <label for="city">{{ $t("Város") }}</label>
            </div>

            <div class="form-floating mb-4" id="zip">
                <input
                    type="text"
                    name="zip"
                    class="form-control mb-4"
                    :placeholder="$t('Irányítószám')"
                    v-model="model.billing.zip"
                />
                <label for="zip">{{ $t("Irányítószám") }}</label>
            </div>

            <div class="form-floating mb-4" id="address">
                <input
                    type="text"
                    name="address"
                    class="form-control mb-4"
                    :placeholder="$t('Utca, házszám')"
                    v-model="model.billing.address"
                />
                <label for="address">{{ $t("Utca, házszám") }}</label>
            </div>

            <div
                class="company-data"
                v-bind:class="{
                    'd-none': parseInt(model.billing.is_company) === 0
                }"
            >
                <div class="form-floating mb-4" id="company">
                    <input
                        type="text"
                        name="company_name"
                        class="form-control mb-4"
                        :placeholder="$t('Cégnév')"
                        v-model="model.billing.company_name"
                    />
                    <label for="company">{{ $t("Cégnév") }}</label>
                </div>

                <div class="form-floating mb-4" id="taxnumber">
                    <input
                        type="text"
                        name="vat_number"
                        class="form-control mb-4"
                        :placeholder="$t('Adószám')"
                        v-model="model.billing.vat_number"
                    />
                    <label for="taxnumber">{{ $t("Adószám") }}</label>
                </div>
            </div>

            <div class="h3 text-center mt-100 mb-5">
                {{ $t("Szállítás adatok") }}
            </div>

            <div class="form-check mb-4">
                <input
                    class="form-check-input"
                    type="checkbox"
                    value="1"
                    id="shippingbilling"
                    v-model="model.billing_same_as_shipping"
                />
                <label class="form-check-label" for="shippingbilling">
                    {{
                        $t(
                            "A számlázási adataim megegyeznek a szállítási adataimmal"
                        )
                    }}
                </label>
            </div>

            <div class="form-floating mb-4" id="country2">
                <input
                    type="text"
                    name="shipping_country"
                    class="form-control mb-4"
                    :placeholder="$t('Ország')"
                    v-model="model.shipping.country"
                />
                <label for="country2">{{ $t("Ország") }}</label>
            </div>

            <div class="form-floating mb-4" id="city2">
                <input
                    type="text"
                    name="shipping_city"
                    class="form-control mb-4"
                    :placeholder="$t('Város')"
                    v-model="model.shipping.city"
                />
                <label for="city2">{{ $t("Város") }}</label>
            </div>

            <div class="form-floating mb-4" id="zip2">
                <input
                    type="text"
                    name="text1"
                    class="form-control mb-4"
                    :placeholder="$t('Irányítószám')"
                    v-model="model.shipping.zip"
                />
                <label for="zip2">{{ $t("Irányítószám") }}</label>
            </div>

            <div class="form-floating mb-4" id="address2">
                <input
                    type="text"
                    name="shipping_address"
                    class="form-control mb-4"
                    :placeholder="$t('Utca, házszám')"
                    v-model="model.shipping.address"
                />
                <label for="address2">{{ $t("Utca, házszám") }}</label>
            </div>

            <div class="text-center mt-4">
                <button type="submit" class="btn btn-primary">
                    {{ $t("Mentés") }}
                </button>
                <a
                    href="#"
                    v-on:click.prevent="deleteProfile"
                    class="mt-4 d-block small-text"
                    >{{ $t("Profil törlése") }}</a
                >
            </div>
        </form>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "Personal",
    data() {
        return {
            model: {}
        };
    },
    computed: {
        ...mapGetters(["currentUser", "isAuthenticated"]),
        url: function() {
            return `${process.env.VUE_APP_API_URL}`;
        }
    },
    watch: {
        currentUser: function(val) {
            this.model = val;
            this.model.roles = null;
        },
        "model.billing_same_as_shipping": function(val) {
            if (val) {
                this.model.shipping.lastname = this.model.billing.lastname;
                this.model.shipping.firstname = this.model.billing.firstname;
                this.model.shipping.country = this.model.billing.country;
                this.model.shipping.city = this.model.billing.city;
                this.model.shipping.zip = this.model.billing.zip;
                this.model.shipping.address = this.model.billing.address;
            }
        }
    },
    beforeMount() {
        this.model = this.currentUser;
        this.model.roles = null;
    },
    methods: {
        updatePersonal() {
            this.$http.put(`${this.url}/user/update-profile`, this.model);
        },
        deleteProfile() {
            var r = confirm(
                this.$translate.t(
                    "Biztosan törölni szeretné a fiókját? A művelet nem visszavonható."
                )
            );

            if (r === true) {
                this.$http.delete(`${this.url}/user/delete-profile`);
            }
        }
    }
};
</script>

<style scoped></style>
